<template>
  <div class="tag">
    <div class="tag-page-header">
      <div class="tag-page-header-heading">
        <span class="tag-page-header-heading-title">客户画像</span>
        <span class="point"><i class="iconfont icon-shu" :style="{fontSize: '16px', color: '#027AFF', marginLeft: '4px'}"></i></span>
      </div>
      <div class="tag-page-header-content">
        <div class="tips-content">
          <div class="tips-content-qr">
            <qrcode class="qr" :value="qrUrl" :options="{ width: 88 }"></qrcode>
            <span>扫码绑定，接受消息通知</span>
          </div>
          <div class="tips-content-value">
            <p class="value">
              <span class="value-title">标签说明</span>

            </p>
            <ol>
              <li>标签系统为您更好链接客户群体</li>
              <li>每个标签可以绑定一个规则</li>
              <li>同一个客户应用相同类型规则只能应用一次</li>
              <li>打开「微信扫一扫」扫描左侧二维码后，点击
                <el-button type="text">推送消息</el-button>
                即可接受测试消息
              </li>
            </ol>
          </div>
          <div class="tips-content-img">
            <img src="../../../../assets/img/tag.png">
          </div>
        </div>
      </div>
    </div>

    <div class="tag-content">
      <el-tabs default-active-key="1">
        <el-tab-pane :label="rule.name" v-for="(rule, idx) in ruleSort" :key="idx">
        </el-tab-pane>
      </el-tabs>

      <el-row :gutter="16">
        <el-col :span="8" v-for="(tag, idx) in tags" :key="idx" style="margin-bottom: 16px">
          <div class="tag-content-item">
            <div class="text"><p class="label">标签名称:</p>
              <p class="value">{{tag.name}}</p></div>
            <div class="text"><p class="label">预警内容:</p>
              <p class="value" v-html="tag.content"></p></div>
            <div class="text"><p class="label">关联客户数:</p>
              <p class="value">{{tag.customers}}</p></div>

            <div class="btn">
              <el-button type="text">查看客户</el-button>
              <el-button type="text">编辑内容</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: '',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        qrUrl: 'http://p.yunka-it.cn/pay/login.html?uuid=6becc3266c01000&webTicket=',
        ruleSort: [
          {name: '制作杯量'},
          {name: '异常停机'},
          {name: '周期趋势'},
        ],
        tags: [
          {
            name: '每周不低于40杯',
            content: '{客户名称}<br/>本周制作杯量:{真实杯量}低于设定{设定值}杯量<br/>请注意关注。',
            customers: 5,
          },
          {
            name: '每周不低于40杯',
            content: '{客户名称}<br/>本周制作杯量:{真实杯量}\n低于设定{设定值}杯量<br/>请注意关注。',
            customers: 5,
          },
          {
            name: '每周不低于40杯',
            content: '{客户名称}<br/>本周制作杯量:{真实杯量}\n低于设定{设定值}杯量<br/>请注意关注。',
            customers: 5,
          },
          {
            name: '每周不低于40杯',
            content: '{客户名称}<br/>本周制作杯量:{真实杯量}\n低于设定{设定值}杯量<br/>请注意关注。',
            customers: 5,
          },
        ]
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .tag {
    @include container();
    &-page-header {
      display: flex;
      margin: 0;
      margin-bottom: $small-space;
      flex-direction: column;
      background-color: #fff;
      border-radius: 0 0 2px 2px;
      box-sizing: border-box;
      color: #252934;
      font-size: 12px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: "tnum";
      position: relative;

      .point {
        position: relative;
      }

      .point:after {
        content: "";
        position: absolute;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        left: 4px;
        top: 24px;
        transform: rotate(45deg);
        border-top: 1px solid $theme-light-5;
        border-left: 1px solid $theme-light-5;
        background-color: #f5f9ff;
        z-index: 2;
        display: block;
      }

      &-heading {
        &-title {
          @include page-title()
        }
      }

      &-content {
        padding: 0;
        overflow: visible;

        .tips-content {
          display: flex;
          background: #f5f9ff;
          border: 1px solid $theme-light-5;
          padding: $middle-space;
          position: relative;
          margin-top: 12px;
          width: 100%;
          color: #6b798e;
          overflow: hidden;
          transition: all .2s ease-out;

          &-value {
            flex: 1;

            .value {
              &-title {
                @include font-large-s();
              }
            }

            ol {
              counter-reset: section;
            }

            li {
              @include font-little();
              ::v-deep .el-button{
                padding: 0;
              }
            }

            li:before {
              counter-increment: section; /* 增加计数器值 */
              content: counter(section) ". "; /* 显示计数器 */
            }
            li:last-child{

            }
          }

          &-qr {
            width: 240px;
            text-align: center;

            .qr {
              display: block;
              margin: auto;
              margin-bottom: $small-space;
              text-align: center;
            }
          }

          &-img {
            margin: auto;

            img {
              width: 160px;
            }
          }
        }
      }
    }

    &-content {

      &-item {
        border: 1px solid $content-border-color;
        padding: $small-space $middle-space;
        margin-right: $small-space ;
        border-radius: $box-radius;
        cursor: pointer;
        position: relative;

        .text {
          display: flex;
          align-items: center;
          margin-bottom: $small-space;
        }

        .label {
          @include font-little();
          width: 64px;
          margin-right: $small-space;
        }

        .value {
          flex: 1;
          @include font-little-h();
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        }

        .btn {
          position: absolute;
          bottom: $middle-space;
          right: $middle-space;
        }
      }

      &-item:hover {
        @include box-shadow();
        // border: none;
      }
    }
  }
</style>
